@font-face {
  font-family: 'canela-bold';
  src: local('canela-bold'), url('./fonts/canela-bold.ttf') format('truetype');
}
@font-face {
  font-family: 'CircularStd-BlackItalic';
  src: local('CircularStd-BlackItalic'), url('./fonts/CircularStd-BlackItalic.otf') format('opentype');
}
@font-face {
  font-family: 'CircularStd-Black';
  src: local('CircularStd-Black'), url('./fonts/CircularStd-Black.otf') format('opentype');
}
@font-face {
  font-family: 'CircularStd-BoldItalic';
  src: local('CircularStd-BoldItalic'), url('./fonts/CircularStd-BoldItalic.otf') format('opentype');
}
@font-face {
  font-family: 'CircularStd-Bold';
  src: local('CircularStd-Bold'), url('./fonts/CircularStd-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'CircularStd-BookItalic';
  src: local('CircularStd-BookItalic'), url('./fonts/CircularStd-BookItalic.otf') format('opentype');
}
@font-face {
  font-family: 'CircularStd-Book';
  src: local('CircularStd-Book'), url('./fonts/CircularStd-Book.otf') format('opentype');
}
@font-face {
  font-family: 'CircularStd-LightItalic';
  src: local('CircularStd-LightItalic'), url('./fonts/CircularStd-LightItalic.otf') format('opentype');
}
@font-face {
  font-family: 'CircularStd-Light';
  src: local('CircularStd-Light'), url('./fonts/CircularStd-Light.otf') format('opentype');
}
@font-face {
  font-family: 'CircularStd-MediumItalic';
  src: local('CircularStd-MediumItalic'), url('./fonts/CircularStd-MediumItalic.otf') format('opentype');
}
@font-face {
  font-family: 'CircularStd-Medium';
  src: local('CircularStd-Medium'), url('./fonts/CircularStd-Medium.otf') format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  display:flex;
  flex-direction:column;
}

header{
  min-height:50px;
}

footer{
  min-height:50px;
}

.main {
  flex:1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
